import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import "./SeoTextIntro.css";

export default function SeoTextIntro() {
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <section class="content-part block-seo" data-test="block-seo">
        <h2>
          <span
            class="logo-avantages-svg"
            innerHTML={
              settingsContext.marketing_segments_terms.invest
                .field_logo_main_svg
            }
          />
          qu’est-ce que c’est ?
        </h2>
        <p>
          <strong>Six solutions clés-en-main</strong> couvrant l’ensemble des
          dispositifs locatifs.
          <br />
          COGEDIM AVANTAGES, c’est un éventail de mesures exceptionnelles pour
          faciliter l’investissement immobilier, quel que soit votre profil ou
          votre capacité d’épargne. Avec nos offres clé en main, bénéficiez d’un
          accompagnement complet : conseil, sélection du bien, montage du
          dossier, gestion locative... Grâce à l’expertise de Cogedim,
          investissez sereinement et sécurisez votre patrimoine en toute
          simplicité.
        </p>
        <ul class="more">
          <li>
            <span class="plus">+</span> de pérennité
          </li>
          <li>
            <span class="plus">+</span> de performance
          </li>
          <li>
            <span class="plus">+</span> de simplicité
          </li>
        </ul>
        <h3>
          <span>C’est maintenant qu'il faut investir&nbsp;!</span>
        </h3>
      </section>
    </>
  );
}
