import imgProxy from "~/utils/imgproxy";
import { createSignal, onCleanup, onMount } from "solid-js";
import { isServer } from "solid-js/web";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import "./FirstScreen.css";

export default function FirstScreen() {
  const [anchorsShouldStack, setanchorsShouldStack] = createSignal(false);

  onMount(() => {
    document.addEventListener("scroll", handleScroll);
  });

  onCleanup(() => {
    if (!isServer) {
      document.removeEventListener("scroll", handleScroll);
    }
  });

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight - 100) {
      setanchorsShouldStack(true);
    } else {
      setanchorsShouldStack(false);
    }
  };

  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <section class="full-visual" data-test="first-screen">
        <picture>
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/cogedim-avantages-visual-landing-desktop.jpg",
              "size:1920:1080/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/cogedim-avantages-visual-landing-desktop.jpg",
              "size:1366:768/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/cogedim-avantages-visual-landing-desktop.jpg",
              "size:1024:576/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 376px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/cogedim-avantages-visual-landing-mobile.jpg",
              "size:750:1134/resizing_type:fill",
            )}
          />
          <source
            media="(max-width: 375px)"
            srcset={imgProxy(
              "/images/nouveau-neuf/cogedim-avantages-visual-landing-mobile.jpg",
              "size:375:667/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              "/images/nouveau-neuf/cogedim-avantages-visual-landing-mobile.jpg",
              `size:375:667/resizing_type:fill`,
            )}
            alt=""
            height="667"
            width="375"
            loading="lazy"
          />
        </picture>

        <div class="content-part heading" data-test="header">
          <h1>
            <span
              class="logo-avantages-svg"
              innerHTML={
                settingsContext.marketing_segments_terms.invest
                  .field_logo_main_svg
              }
            />
            redonne le pouvoir
            <br />
            aux investisseurs.
          </h1>
          <p data-test="catchline">
            Les solutions les plus performantes pour investir dans l’immobilier.
          </p>
          <div class="buttons" data-test="anchors">
            <a href="#solutions" class="btn" data-test="anchor">
              Découvrir les solutions
            </a>
            <a href="#accompagnement" class="btn" data-test="anchor">
              Être contacté par un expert
            </a>
          </div>
        </div>

        <div
          class="stackbar"
          data-test="stackbar"
          classList={{ stacked: anchorsShouldStack() }}
        >
          <p>
            <strong>Cogedim Avantages</strong> redonne le pouvoir aux
            investisseurs.
          </p>
          <div class="buttons" data-test="anchors">
            <a href="#solutions" class="btn" data-test="anchor">
              Découvrir les solutions
            </a>
            <a href="#accompagnement" class="btn" data-test="anchor">
              Être contacté par un expert
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
